import React, { useContext, useState, useEffect } from "react";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Sidebar from "examples/Navbars/SideBar";
import SimpleFooter from "examples/Footers/SimpleFooter";
import routes from "routes";
import { AuthContext } from "contexts/AuthContext";
import { DataGrid } from "@mui/x-data-grid";
import MKButton from "components/MKButton";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Dialog from "@mui/material/Dialog";
import SignInModal from "components/SignInModal";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import dayjs from "dayjs"; // For date manipulation
import languages from "components/languages"; // Adjust the import path accordingly
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop"; // Import Backdrop

function DownloadCenter() {
  const { user } = useContext(AuthContext);
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state
  const [selectedRows, setSelectedRows] = useState([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [fetchError, setFetchError] = useState("");
  const [openSignInModal, setOpenSignInModal] = useState(false);
  const [downloading, setDownloading] = useState({});

  // Fetch data once on component mount
  useEffect(() => {
    if (user) {
      fetchData();
    }
  }, [user]);

  useEffect(() => {
    if (!user) {
      setOpenSignInModal(true);
    } else {
      setOpenSignInModal(false);
    }
  }, [user]);

  // Call checkJobStatusSync once when user is defined or jobs change
  useEffect(() => {
    if (user) {
      checkJobStatusSync();
    }
  }, [user, videos]);

  const fetchData = async () => {
    try {
      const videosResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/user/videos`, {
        method: "GET",
        credentials: "include",
      });

      if (!videosResponse.ok) {
        throw new Error("Failed to fetch videos");
      }

      const videosData = await videosResponse.json();
      setVideos(videosData.jobs);
      setFetchError(""); // Clear any previous errors
    } catch (error) {
      console.error("There was an error fetching data!", error);
      setFetchError("Failed to load videos. Please try again later.");
    } finally {
      setLoading(false); // Stop loading once data is fetched
    }
  };

  // Inside your DownloadCenter component, replace the interval-based polling with this function
  const checkJobStatusSync = async () => {
    if (!user) return;

    for (const job of videos) {
      if (job.status === "inProgress") {
        try {
          const statusResponse = await updateJobStatus(job.jobId);
          const updatedJob = statusResponse.job;
          if (updatedJob.status === "finished" || updatedJob.status === "failed") {
            continue; // Move to next job if current job is no longer "inProgress"
          } else {
            console.log("Media still in progress, trying again...");
            await new Promise((resolve) => setTimeout(resolve, 15000)); // Adjust time as needed
          }
        } catch (error) {
          console.error("Error updating job status:", error);
        }
      }
    }
    fetchData();
  };

  const updateJobStatus = async (jobId) => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/update-job`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userId: user.userId,
        jobId,
      }),
      credentials: "include",
    });

    if (!response.ok) {
      throw new Error("Failed to update job status");
    }

    return response.json(); // Return the updated job data
  };

  const handleDelete = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/delete-job`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId: user.userId,
          jobIds: selectedRows,
        }),
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error("Error deleting job(s)");
      }

      setVideos((prevVideos) => prevVideos.filter((job) => !selectedRows.includes(job.jobId)));
      setSelectedRows([]);
      setOpenDeleteDialog(false);
    } catch (error) {
      console.error("Error deleting job(s):", error);
      setFetchError("Failed to delete job(s). Please try again later.");
    }
  };

  const handleDownload = async (videoKey, event, fileName) => {
    event.stopPropagation();
    setDownloading((prev) => ({ ...prev, [videoKey]: true })); // Set the specific video as downloading

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/media/download`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ userId: user.userId, videoKey }),
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error("Failed to get download URL");
      }

      const { url } = await response.json();
      const fileResponse = await fetch(url);

      if (!fileResponse.ok) {
        throw new Error("Failed to fetch file from URL");
      }

      const blob = await fileResponse.blob();
      const objectUrl = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = objectUrl;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(objectUrl);
    } catch (error) {
      console.error("Error downloading file:", error);
      setFetchError("Failed to download file. Please try again later.");
    } finally {
      setDownloading((prev) => ({ ...prev, [videoKey]: false })); // Reset downloading state
    }
  };

  const handleRetry = async (jobId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/retry-job`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          jobId,
          userId: user.userId,
        }),
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error("Error retrying job");
      }

      const result = await response.json();

      // Check if the job object is returned
      if (result.job) {
        const { job } = result;

        // Update the videos list with the retried job
        setVideos((prevVideos) =>
          prevVideos.map((existingJob) =>
            existingJob.jobId === jobId ? { ...existingJob, ...job } : existingJob
          )
        );

        // If the retried job is now in progress, ensure the interval continues checking
        if (job.status === "inProgress") {
          console.log("Job is now in progress, manually triggering status check.");
        }
      } else {
        console.log("No job object returned from retry.");
      }
    } catch (error) {
      console.error("Error retrying job:", error);
      setFetchError("Failed to retry job. Please try again later.");
    }
  };

  const calculateExpiryDays = (finishedDate) => {
    const expiryDate = dayjs(finishedDate).add(2, "month");
    const today = dayjs();
    const daysRemaining = expiryDate.diff(today, "day");
    return daysRemaining >= 0 ? daysRemaining : 0;
  };

  const statusColors = {
    queued: "255, 167, 38", // Orange in RGB
    finished: "102, 187, 106", // Green in RGB
    failed: "239, 83, 80", // Red in RGB
    expired: "158, 158, 158", // Grey in RGB
    inProgress: "66, 133, 244", // Blue in RGB
  };

  const columns = [
    {
      field: "uploadDate",
      headerName: "Upload Date",
      width: 220,
      sortable: true,
      renderCell: (params) => {
        const date = new Date(params.value);
        return date
          .toLocaleString("en-US", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          })
          .replace(",", "");
      },
    },
    { field: "fileName", headerName: "File Name", width: 250, sortable: true },
    {
      field: "sourceLanguage",
      headerName: "Source Language",
      width: 150,
      sortable: true,
      renderCell: (params) => languages[params.value] || params.value, // Display full language name
    },
    {
      field: "dubLanguage",
      headerName: "Dub Language",
      width: 150,
      sortable: true,
      renderCell: (params) => languages[params.value] || params.value, // Display full language name
    },
    {
      field: "status",
      headerName: "Status",
      width: 140,
      sortable: true,
      renderCell: (params) => {
        let status = params.value;
        let backgroundColor = `rgba(${statusColors[status]}, 0.8)`;

        if (status === "finished" && params.row.finishedDate) {
          const daysRemaining = calculateExpiryDays(params.row.finishedDate);
          if (daysRemaining === 0) {
            status = "expired";
            backgroundColor = `rgba(${statusColors["expired"]}, 0.8)`;
          }
        }

        return (
          <MKBox
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <MKBox
              sx={{
                backgroundColor,
                color: "#fff",
                padding: "4px 4px",
                borderRadius: "4px",
                textAlign: "center",
                lineHeight: "2.2",
                minHeight: "auto",
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {status}
            </MKBox>
            {status === "inProgress" && (
              <CircularProgress
                size={16} // Adjust size of the spinner
                sx={{
                  color: `rgba(${statusColors[status]}, 0.8)`, // Blue color
                  marginLeft: "8px", // Space between status box and spinner
                }}
              />
            )}
          </MKBox>
        );
      },
    },
    {
      field: "expiresIn",
      headerName: "Expires In",
      width: 150,
      sortable: true,
      renderCell: (params) => {
        if (params.row.status === "finished" && params.row.finishedDate) {
          const daysRemaining = calculateExpiryDays(params.row.finishedDate);
          return daysRemaining > 0 ? `${daysRemaining}D` : "N/A";
        }
        return "N/A";
      },
    },
    {
      field: "download",
      headerName: "Download / Re-Try",
      width: 160,
      sortable: false,
      renderCell: (params) => {
        const isDownloading = downloading[params.row.dubbedVideoKey];
        const isExpired =
          params.row.status === "finished" &&
          params.row.finishedDate &&
          calculateExpiryDays(params.row.finishedDate) === 0;

        if (params.row.status === "failed") {
          return (
            <MKButton
              variant="contained"
              color="warning"
              onClick={(event) => handleRetry(params.row.jobId, event)}
            >
              Re-Try
            </MKButton>
          );
        } else {
          return (
            <MKButton
              variant="contained"
              color={isDownloading ? "info" : "info"}
              disabled={isDownloading || params.row.status !== "finished" || isExpired}
              onClick={(event) =>
                handleDownload(params.row.dubbedVideoKey, event, params.row.fileName)
              }
              sx={{
                backgroundColor: isDownloading ? "#0d47a1" : undefined, // Darken button when downloading
                color: isDownloading ? "rgba(255, 255, 255, 0.75)" : undefined, // Slightly lighter text color
                position: "relative",
                cursor: isDownloading ? "not-allowed" : "pointer", // Prevent pointer cursor
              }}
            >
              {isDownloading && (
                <CircularProgress
                  size={24}
                  sx={{
                    color: "rgba(255, 255, 255, 1)", // White loading spinner
                    position: "absolute",
                  }}
                />
              )}
              {isDownloading ? "Download" : "Download"}
            </MKButton>
          );
        }
      },
    },
  ];

  const handleDeleteIconClick = () => {
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const selectedRoutes = [routes[0], routes[2], routes[3], routes[4]];
  const selectedRoutesFoot = [routes[5], routes[1]]; // Adjust indices as needed

  return (
    <MKBox display="flex" minHeight="100vh">
      <Sidebar routes={selectedRoutes} footroutes={selectedRoutesFoot} />
      <MKBox
        sx={{
          marginLeft: "0px", // Use 70px for collapsed sidebar, 240px for expanded
          padding: "20px",
          flex: 1,
          display: "flex",
          flexDirection: "column",
          mt: 5,
        }}
      >
        <MKBox
          borderRadius="lg"
          shadow="xl"
          p={3}
          sx={{
            width: "200%",
            maxWidth: "1350px",
            margin: "auto",
            backgroundColor: "white",
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
            flex: 1, // Make the content area grow and push the footer down
          }}
        >
          <MKTypography variant="h4" textAlign="left">
            Download Center
          </MKTypography>
          <MKTypography variant="body2" textAlign="left" mb={3}>
            View status and download dubbed media
          </MKTypography>

          {fetchError && (
            <MKTypography variant="body1" color="error" textAlign="center" mb={2}>
              {fetchError}
            </MKTypography>
          )}

          {/* Loading Backdrop */}
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>

          <MKBox sx={{ height: 650, width: "100%" }} mt={3}>
            <DataGrid
              rows={videos}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 10 },
                },
                sorting: {
                  sortModel: [{ field: "uploadDate", sort: "desc" }], // Sort by uploadDate by default
                },
              }}
              pageSizeOptions={[10, 20, 50]}
              paginationMode="client" // Handle pagination on the client-side
              getRowId={(row) => row.jobId} // Ensure each row has a unique identifier
              onRowSelectionModelChange={(newSelection) => {
                setSelectedRows(newSelection);
              }}
              sx={{
                "& .MuiDataGrid-columnHeaderTitle": {
                  color: "dark.main", // Text color of the header
                  fontWeight: "bold", // Optional: make the header text bold
                },
              }}
              checkboxSelection
            />
            {selectedRows.length > 0 && (
              <MKBox
                sx={{
                  position: "relative",
                  display: "flex",
                  justifyContent: "flex-start", // Align to the left
                  alignItems: "center", // Align vertically with pagination
                  mt: -6.5, // Adjust margin top to bring it in line with the pagination
                  ml: 15, // Add some left margin for spacing
                  zIndex: 1, // Ensure it appears above other content
                }}
              >
                <IconButton
                  color="secondary"
                  onClick={handleDeleteIconClick}
                  size="large"
                  aria-label="delete"
                >
                  <DeleteIcon />
                </IconButton>
              </MKBox>
            )}
          </MKBox>
        </MKBox>
        <MKBox width="100%" mt="auto">
          <SimpleFooter dark />
        </MKBox>

        <SignInModal open={openSignInModal} />

        {/* Delete Confirmation Dialog */}
        <Dialog
          open={openDeleteDialog}
          onClose={handleCloseDeleteDialog}
          aria-labelledby="delete-dialog-title"
          aria-describedby="delete-dialog-description"
        >
          <DialogTitle id="delete-dialog-title">{"Confirm Deletion"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="delete-dialog-description">
              Are you sure you want to delete the selected job(s)? This action cannot be undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <MKButton onClick={handleCloseDeleteDialog} color="primary">
              Cancel
            </MKButton>
            <MKButton onClick={handleDelete} color="secondary" autoFocus>
              Delete
            </MKButton>
          </DialogActions>
        </Dialog>
      </MKBox>
    </MKBox>
  );
}

export default DownloadCenter;

import React from "react";
import { Grid, Container } from "@mui/material";
import MKBox from "components/MKBox";
import MKBadge from "components/MKBadge";
import VideoCard from "components/VideoCard";

function Pages() {
  const videoData = [
    {
      videoSrc: "videos/AFClip2.mp4",
      posterSrc: "thumbnails/wednesday.jpg",
      title: "Video 1",
    },
    {
      videoSrc: "videos/AF_Chinese.mp4",
      posterSrc: "thumbnails/wednesday.jpg",
      title: "Video 2",
    },
    {
      videoSrc: "videos/Ghandi_English.mp4",
      posterSrc: "thumbnails/conference.png",
      title: "Video 3",
    },
    {
      videoSrc: "videos/Ghandi_German.mp4",
      posterSrc: "thumbnails/conference.png",
      title: "Video 4",
    },
    // Add more video data here if needed
  ];

  const renderData = videoData.map(({ videoSrc, posterSrc, title }) => (
    <Grid
      item
      xs={12}
      sm={12}
      md={6}
      lg={12}
      xl={6}
      sx={{
        display: "flex",
        justifyContent: "center",
      }}
      key={title}
    >
      <MKBox
        sx={{
          width: "100%",
          maxWidth: "420px",
        }}
      >
        <VideoCard videoSrc={videoSrc} posterSrc={posterSrc} />
      </MKBox>
    </Grid>
  ));

  return (
    <MKBox component="section" py={4}>
      <Container>
        <Grid
          container
          item
          flexDirection="column"
          alignItems="center"
          sx={{ textAlign: "center", my: 4, mx: "auto", px: 0.75 }}
        >
          <MKBadge
            variant="contained"
            color="info"
            badgeContent="see examples"
            container
            sx={{ mb: -15 }}
          />
        </Grid>
      </Container>
      <Grid container justifyContent="center" alignItems="center" spacing={3}>
        {renderData}
      </Grid>
    </MKBox>
  );
}

export default Pages;

import React, { useContext, useEffect, useState, useRef } from "react";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import MKProgress from "components/MKProgress";
import { AuthContext } from "contexts/AuthContext";
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import { useShopFunctions } from "pages/shop/shopFunctions";
import { useNavigate } from "react-router-dom";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import SignInModal from "components/SignInModal";
import InfoModal from "components/InfoModal"; // Import the new modal
import languages from "components/languages";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { CircularProgress } from "@mui/material";

function TrialBlock() {
  const [openModal, setOpenModal] = useState(false);
  const [openInfoModal, setOpenInfoModal] = useState(false);
  const [infoMessage, setInfoMessage] = useState("");
  const [infoTitle, setInfoTitle] = useState("");
  const [dragOver, setDragOver] = useState(false);
  const [selectedFileName, setSelectedFileName] = useState("");
  const [openSignInModal, setOpenSignInModal] = useState(false);
  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [paypalKey, setPaypalKey] = useState(0); // State to force PayPal buttons to re-render
  const fileInputRef = useRef(null); // Ref for file input
  const [loadingPayment, setLoadingPayment] = useState(false);

  const { user } = useContext(AuthContext);
  const {
    sourceLanguage,
    videoMetadata,
    dubLanguage,
    progress,
    handleSourceLanguageChange,
    handleDubLanguageChange,
    handleFileChange,
    handleCheckout,
    freeTrials,
    errorMessage,
    successMessage,
    clearMessages,
    checkoutSuccessful,
    setCheckoutSuccessful,
    cost,
    resetFields,
    useFreeTrial,
    setUseFreeTrial, // Directly from the hook
  } = useShopFunctions(user);

  useEffect(() => {
    if (checkoutSuccessful) {
      setOpenModal(true);
      setIsLoading(false);
      resetFields(); // Reset fields after checkout success
    }
  }, [checkoutSuccessful]);

  useEffect(() => {
    if (errorMessage || successMessage) {
      const timer = setTimeout(() => {
        clearMessages();
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [errorMessage, successMessage, clearMessages]);

  const showModal = (message, title = "Notice") => {
    setInfoMessage(message);
    setInfoTitle(title);
    setOpenInfoModal(true);
  };

  const handleFileChangeWithCheck = (event) => {
    if (!user) {
      showModal("You need to sign in to upload media.");
      setOpenSignInModal(true); // Open the sign-in modal if not signed in
      return;
    }
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setSelectedFileName(selectedFile.name); // Update file name on upload
      handleFileChange(event); // Call the handleFileChange to process the upload
      if (fileInputRef.current) {
        fileInputRef.current.value = null;
      }
    }
  };

  const handleCheckoutWithCheck = () => {
    if (!user) {
      showModal("You need to sign in to dub media.");
      return;
    }

    if (!videoMetadata) {
      showModal("Please upload a video file.");
      return;
    }
    if (!sourceLanguage || !dubLanguage) {
      showModal("Please select both source and dub languages.");
      return;
    }

    // Check if the user has selected to use a free trial but has no remaining free trials
    if (useFreeTrial && freeTrials <= 0) {
      showModal(
        "No free trials remaining. Please purchase a plan or deselect the free trial option."
      );
      return;
    }

    setIsLoading(true);
    handleCheckout();
  };

  const navigate = useNavigate();

  const handleGoToDownloadCenter = () => {
    navigate("/download-center");
    handleCloseModal();
  };

  const checkSubscriptionStatus = async () => {
    if (user && user.subscription !== "free") {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/api/user/subscription-status/${user.subscriptionId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
            credentials: "include",
          }
        );

        if (response.ok) {
          const { status } = await response.json();

          // If the subscription is inactive, switch to "free"
          if (status !== "ACTIVE") {
            await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/user/subscription`, {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({
                newSubscription: "free",
                billingCycle: "none",
                subscriptionId: null,
              }),
              credentials: "include",
            });
            showModal(
              "Your subscription is inactive and has been changed to 'Free'. Please retry."
            );
            return false; // Prevent proceeding to the payment modal
          }
        } else {
          throw new Error("Error fetching subscription status");
        }
      } catch (error) {
        console.error("Subscription status check failed:", error);
        showModal("Unable to verify subscription status. Please try again.");
        return false;
      }
    }
    return true;
  };

  const handleOpenPaymentModalWithChecks = async () => {
    // Check video metadata, languages, and subscription status
    if (!videoMetadata) {
      showModal("Please upload a video file.");
      return;
    }
    if (!sourceLanguage || !dubLanguage) {
      showModal("Please select both source and dub languages.");
      return;
    }

    if (!user) {
      showModal("You need to sign in to dub media.");
      return;
    }

    // Only open payment modal if the subscription check passes
    const canProceed = await checkSubscriptionStatus();
    if (canProceed) {
      setLoadingPayment(true);
      setOpenPaymentModal(true);
      setPaypalKey((prev) => prev + 1); // Increment key to force re-render of PayPal buttons
    }
  };

  const handleClosePaymentModal = () => {
    setOpenPaymentModal(false);
    setLoadingPayment(false);
  };

  const handleCloseModal = () => {
    resetFields();
    setSelectedFileName(""); // Reset the displayed file name
    setOpenModal(false);
    setCheckoutSuccessful(false);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setDragOver(true);
  };

  const handleDragLeave = () => {
    setDragOver(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setDragOver(false);

    // Check if the user is signed in
    if (!user) {
      showModal("You need to sign in to upload media.");
      return;
    }

    const files = event.dataTransfer.files;
    if (files && files.length > 0) {
      const eventMock = {
        target: {
          files: files,
        },
      };
      setSelectedFileName(files[0].name);
      handleFileChangeWithCheck(eventMock);
      // Reset the input value here too
      if (fileInputRef.current) {
        fileInputRef.current.value = null;
      }
    }
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleOpenSignInModal = () => setOpenSignInModal(true);
  const handleCloseSignInModal = () => setOpenSignInModal(false);

  return (
    <MKBox component="section" py={8}>
      <Grid container spacing={3} justifyContent="center" alignItems="center">
        <Grid item>
          <Card
            variant="gradient"
            borderRadius="lg"
            shadow="xl"
            sx={{
              backgroundColor: "white",
              padding: "2rem",
              width: { xs: "320px", sm: "500px", md: "500px", lg: "500px" },
              maxWidth: "100%",
            }}
          >
            <MKBox
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
              textAlign="center"
              p={2}
              mb={3}
            >
              <MKTypography variant="h4" fontWeight="medium" color="white">
                Try Now For Free
              </MKTypography>
            </MKBox>

            {errorMessage && (
              <MKTypography variant="body2" color="error" mb={2}>
                {errorMessage}
              </MKTypography>
            )}
            {successMessage && (
              <MKTypography variant="body2" color="success" mb={2}>
                {successMessage}
              </MKTypography>
            )}

            <MKTypography variant="h5" mb={2} color="dark">
              1. Upload Media
            </MKTypography>
            <MKBox
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
              mb={2}
              sx={{
                position: "relative",
                border: "2px dashed grey",
                borderRadius: "8px",
                padding: "5px 20px",
                textAlign: "center",
                cursor: "pointer",
                backgroundColor: dragOver ? "lightgrey" : "transparent",
              }}
            >
              <input
                type="file"
                ref={fileInputRef} // Attach ref to the input element
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  opacity: 0,
                  cursor: "pointer",
                }}
                onChange={handleFileChangeWithCheck}
              />
              <UploadFileOutlinedIcon fontSize="large" color="secondary" />
              <MKTypography variant="body2" color="textSecondary" mb={1}>
                {selectedFileName || "Drag and drop or click to upload"}
              </MKTypography>
            </MKBox>
            {progress > 0 && (
              <MKBox mb={4}>
                <MKProgress color="info" value={progress} />
                <MKTypography variant="body2" color="text" mt={1}>
                  {progress}% uploaded
                </MKTypography>
              </MKBox>
            )}

            <MKBox mb={2}>
              <MKTypography variant="h5" mb={2} color="dark">
                2. Select Languages
              </MKTypography>
              <Grid container spacing={3} justifyContent="center" mb={1}>
                <Grid item xs={12} md={5.1}>
                  <MKTypography variant="body2" color="textSecondary" mb={1}>
                    Select Source Language
                  </MKTypography>
                  <FormControl fullWidth variant="standard" required>
                    <InputLabel>Source Language</InputLabel>
                    <Select
                      value={sourceLanguage}
                      onChange={handleSourceLanguageChange}
                      label="Source Language"
                      MenuProps={MenuProps}
                    >
                      {Object.entries(languages).map(([code, name]) => (
                        <MenuItem key={code} value={code}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={5}>
                  <MKTypography variant="body2" color="dark.main" mb={1}>
                    Select Dub Language
                  </MKTypography>
                  <FormControl fullWidth variant="standard" required>
                    <InputLabel>Dub Language</InputLabel>
                    <Select
                      value={dubLanguage}
                      onChange={handleDubLanguageChange}
                      label="Dub Language"
                      MenuProps={MenuProps}
                    >
                      {Object.entries(languages).map(([code, name]) => (
                        <MenuItem key={code} value={code}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </MKBox>

            <MKTypography variant="h5" mb={2} color="dark">
              3. Free Trial
            </MKTypography>

            <FormControlLabel
              control={
                <Checkbox
                  checked={useFreeTrial} // Use useFreeTrial from the hook
                  onChange={(e) => setUseFreeTrial(e.target.checked)} // Use setUseFreeTrial from the hook
                  color="primary"
                />
              }
              label={
                user ? (
                  `Use a free trial (${freeTrials} remaining)`
                ) : (
                  <MKTypography variant="body2" color="dark">
                    Use a free trial (
                    <MKTypography
                      variant="body2"
                      component="span"
                      onClick={handleOpenSignInModal}
                      sx={{
                        color: "blue",
                        cursor: "pointer",
                      }}
                    >
                      Sign In
                    </MKTypography>
                    )
                  </MKTypography>
                )
              }
              sx={{ mb: 2 }}
            />
            {!useFreeTrial && (
              <MKTypography variant="h6" color="dark" mb={2}>
                Estimated Cost: ${cost.toFixed(2)}
              </MKTypography>
            )}
            {useFreeTrial && (
              <MKTypography variant="body2" color="dark" mb={2}>
                - Free trial media must be under 1min
              </MKTypography>
            )}

            <MKTypography variant="caption" color="textSecondary" align="center" mb={-1}>
              By proceeding, you agree to our{" "}
              <MKTypography
                variant="caption"
                component="span"
                color="info"
                sx={{ cursor: "pointer" }}
                onClick={() => navigate("/terms-of-service")}
              >
                Terms of Service
              </MKTypography>
              .
            </MKTypography>

            <MKBox sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
              <MKButton
                variant="contained"
                color="info"
                size="large"
                onClick={useFreeTrial ? handleCheckoutWithCheck : handleOpenPaymentModalWithChecks}
                sx={{ width: "380px" }}
                disabled={isLoading}
              >
                {isLoading ? "Uploading..." : "Dub Media"}
              </MKButton>
            </MKBox>
          </Card>
        </Grid>
      </Grid>

      {/* Success Modal */}
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <MKBox
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            textAlign: "center",
          }}
          borderRadius="lg"
        >
          <MKTypography variant="h6" component="h2">
            Checkout successful
          </MKTypography>
          <MKTypography sx={{ mt: 2 }}>
            Visit your profile to view details and download dubbed media.
          </MKTypography>
          <MKButton
            variant="contained"
            color="primary"
            onClick={handleGoToDownloadCenter}
            sx={{ mt: 2 }}
          >
            Download Center
          </MKButton>
        </MKBox>
      </Modal>

      {/* Payment Modal */}
      <Modal
        open={openPaymentModal}
        onClose={handleClosePaymentModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <MKBox
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            textAlign: "center",
          }}
          borderRadius="lg"
        >
          <MKTypography variant="h5" component="h2" mb={4}>
            Payment Options
          </MKTypography>
          {loadingPayment && (
            <MKBox
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingY: 2, // Add space above and below the icon
              }}
            >
              <CircularProgress color="info" />
            </MKBox>
          )}
          <PayPalScriptProvider
            options={{
              "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
            }}
          >
            <PayPalButtons
              key={paypalKey}
              createOrder={(data, actions) => {
                return actions.order.create({
                  purchase_units: [
                    {
                      amount: {
                        value: useFreeTrial ? "0.00" : cost.toFixed(2),
                      },
                    },
                  ],
                  application_context: {
                    shipping_preference: "NO_SHIPPING",
                  },
                });
              }}
              onInit={() => setLoadingPayment(false)} // Hide spinner once buttons are ready
              onApprove={() => {
                handleClosePaymentModal();
                setIsLoading(true);
                handleCheckout(); // Call your existing checkout logic
                resetFields(); // Reset the fields after a successful payment
                setSelectedFileName(""); // Reset the displayed file name
              }}
              onError={(err) => {
                console.error("PayPal Checkout Error:", err);
              }}
            />
          </PayPalScriptProvider>

          <MKButton
            variant="outlined"
            color="error"
            onClick={handleClosePaymentModal}
            sx={{ mt: 2 }}
          >
            Cancel
          </MKButton>
        </MKBox>
      </Modal>

      {/* Processing Modal */}
      <Modal
        open={isLoading}
        onClose={() => setIsLoading(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <MKBox
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 300,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            textAlign: "center",
          }}
          borderRadius="lg"
        >
          <MKTypography variant="h6" component="h2">
            Processing Request...
          </MKTypography>
          <MKTypography sx={{ mt: 2 }}> Please wait while we process your request. </MKTypography>
        </MKBox>
      </Modal>

      <InfoModal
        open={openInfoModal}
        handleClose={() => setOpenInfoModal(false)}
        message={infoMessage}
        title={infoTitle}
      />

      {/* Sign-In Modal */}
      <SignInModal open={openSignInModal} handleClose={handleCloseSignInModal} />
    </MKBox>
  );
}

export default TrialBlock;
